import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IOfferResponse from '@/modules/offer/interfaces/offer-response.interface'
import moment from 'moment'

export default class Offer extends Model<IModelResponse> {
  static ALIAS: string = 'offer'

  title!: string
  client!: Object
  clientId!: number
  date!: string
  dueDate!: string
  number!: number
  author!: Object
  information!: Object[]
  manager!: Object
  managerId!: number
  total!: number
  locale!: string
  totalDiscount!: number
  totalVat!: number
  totalWithVat!: number
  notes!: string
  tinyUrl!: string
  status!: string

  transform(data: IOfferResponse): void {
    super.transform(data)

    this.title = data.title
    this.client = data.client
    this.clientId = data.client_id
    this.date = moment(data.date).format('YYYY-MM-DD')
    this.dueDate = moment(data.due_date).format('YYYY-MM-DD')
    this.number = data.number
    this.author = data.author
    this.manager = data.manager
    this.managerId = data.manager_id
    this.information = data.information
    this.locale = data.locale
    this.total = data.total
    this.totalDiscount = data.total_discount
    this.totalVat = data.total_vat
    this.totalWithVat = data.total_with_vat
    this.notes = data.notes
    this.status = data.status
    if (data.tiny_url) this.tinyUrl = data.tiny_url
  }
}
