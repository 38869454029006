




















import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import PrepaidInvoice from '@/modules/prepaid-invoices/models/prepaid-invoice.model'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import { InvoiceStatusKeys, InvoiceStatusKeysData, InvoiceTypes } from '@/shared/configs/invoice/invoice.config'
import SelectOption from '@/shared/classes/components/form/select-option'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import { FieldTypes } from '@/shared/components/form/field-types'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import http from '@/shared/helpers/http'
import fileBuffer from '@/shared/helpers/file-buffer-helper'
import highlightOverdue from '@/shared/helpers/data-table/highlight-overdue';
import { tableCount } from '@/helpers/tableCount';
import CheckboxField from '@/shared/classes/components/form/fields/checkbox-field';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class PrepaidInvoiceIndex extends Vue {
  table: DataTableBase | any = null
  downloaded: boolean = true

  @Watch('$route', { immediate: true, deep: true }) private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.prepaidInvoicesIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.prepaidInvoicesIndex)
      .setModel(PrepaidInvoice)
      .setEndpoint('/invoices')
      .setFilterAlwaysOpen(true)
      .setRowClassCallback(highlightOverdue)
      .setRowClickCallback((item:PrepaidInvoice) => {
        this.$router.push({ name: GeneralRoutes.prepaidInvoice, params: { id: item.id.toString() } })
      })
      .setFilter([
        new DataTableFilter().setOperator(FilterOperators.in).setField(
          new SearchableField()
            .setNoUuid(true)
            .setMultiple(true)
            .setKey('client_id')
            .setLoadItemSort('-created_at')
            .setDisplayKey('company_name')
            .setTitle('Klientas')
            .setValueKey('id')
            .setSize(FieldSizes.threeTwelfth)
            .loadItems({
              endpoint: '/clients',
              value: 'id',
              title: 'company_name'
            })
        ),
        new DataTableFilter().setOperator(FilterOperators.equals).setField(
          new SearchableField()
            .setNoUuid(true)
            .setKey('manager.full_name')
            .setTitle('Vadybininkas')
            .setDisplayKey('full_name')
            .setValueKey('full_name')
            .setSize(FieldSizes.threeTwelfth)
            .loadItems({
              endpoint: '/users',
              value: 'id',
              title: 'full_name'
            })
        ),
        new DataTableFilter()
          .setFilterReturnParam(this.dateFilter)
          .setFilterSetValue(this.dateSetFilter)
          .setField(
            new Field()
              .setType(FieldTypes.dateFilter)
              .setSize(FieldSizes.half)
              .setTitle('Data')
              .setKey('date')
              .setSelectOptions([
                new SelectOption().setKey('current-month').setTitle('Einamasis mėnuo'),
                new SelectOption().setKey('last-month').setTitle('Praėjęs mėnuo'),
                new SelectOption().setKey('select').setTitle('Pasirinktina data')
              ])
          ),
        new DataTableFilter().setOperator(FilterOperators.in).setField(
          new CheckboxField()
            .setKey('status')
            .setMultiple(true)
            .setTitle('Statusas')
            .setSize(FieldSizes.full)
            .setType(FieldTypes.checkboxBlock)
            .setSelectOptions([
              new SelectOption()
                .setKey(InvoiceStatusKeys.unpaid)
                .setTitle(InvoiceStatusKeysData[InvoiceStatusKeys.unpaid]),
              new SelectOption()
                .setKey(InvoiceStatusKeys.partiallyPaid)
                .setTitle(InvoiceStatusKeysData[InvoiceStatusKeys.partiallyPaid]),
              new SelectOption().setKey(InvoiceStatusKeys.paid).setTitle(InvoiceStatusKeysData[InvoiceStatusKeys.paid])
            ])
        ),
        { operator: 'equals', type: 'type', value: InvoiceTypes.prepaid }
      ])
      .setHeaders([
        new DataTableHeader()
          .setKey('number')
          .setLabel(__('views.invoices.index.table.columns.number'))
          .setLink({ link: GeneralRoutes.prepaidInvoice, linkParam: 'id' })
          .setPopover('notes'),
        new DataTableHeader().setKey('client.company_name').setLabel(__('views.invoices.index.table.columns.client')),
        new DataTableHeader().setKey('manager.full_name').setLabel(__('views.invoices.index.table.columns.manager')),
        new DataTableHeader().setKey('totalWithVat').setLabel(__('views.invoices.index.table.columns.totalWithVat')),
        new DataTableHeader().setKey('payed').setLabel(__('views.invoices.index.table.columns.payed')),
        new DataTableHeader().setKey('leftPay').setLabel(__('views.invoices.index.table.columns.leftPay')),
        new DataTableHeader().setKey('date').setLabel(__('views.invoices.index.table.columns.date')),
        new DataTableHeader().setKey('dueDate').setLabel(__('views.invoices.index.table.columns.dueDate')),
        new DataTableHeader()
          .setKey('status')
          .setLabel(__('views.invoices.index.table.columns.status'))
          .setFunctionalClasses((data: PrepaidInvoice) => `data-table__status data-table__status--${data.status}`)
          .setCustomText((data: PrepaidInvoice) => {
            if (data.status === 'paid') return __('common-names.paid')

            if (data.status === 'partially-paid') return __('common-names.partially-paid')

            if (data.status === 'not-approved') return __('common-names.unapproved')

            return __('common-names.unpaid')
          })
      ])
      .setSumCheckboxes(true)
  }

  addNew(): void {
    this.$router.push({ name: GeneralRoutes.prepaidInvoicesCreate })
  }

  dateFilter(data: any) {
    const from = data.from ? { [`filter.${FilterOperators.gte}.date`]: data.from } : ''
    const to = data.to ? { [`filter.${FilterOperators.lte}.date`]: data.to } : ''
    return { ...from, ...to }
  }

  get totals(): any {
    const data: any = {
      total: 0
    }

    this.table.data.forEach((row: any) => {
      data.total += row.total
    })

    return data
  }

  get routeFilter(): string {
    let query: string = `?filter[equals.type]=${InvoiceTypes.prepaid}`
    Object.keys(this.$route.query).forEach((key: string) => {
      if (key.startsWith('filter.')) {
        query += `&filter[${key.replace(/filter./g, '')}]=${this.$route.query[key]}`
      }

      if (key === 'q') query += `&${key}=${this.$route.query[key]}`
    })
    return query
  }

  downloadPDF(): void {
    this.downloaded = false
    http
      .get(`invoice-pdf/by-request${this.routeFilter}`, { responseType: 'blob' })
      .then(response => {
        fileBuffer.downloadFileBufferWithoutName(response, 'Prepaid-Invoices.pdf')
      })
      .finally(() => {
        this.downloaded = true
      })
  }

  dateSetFilter(field: any, data: any, operator?: string) {
    if (operator === FilterOperators.gte) {
      field.value = {
        ...field.value,
        from: data
      }
    }

    if (operator === FilterOperators.lte) {
      field.value = {
        ...field.value,
        to: data
      }
    }
  }
}
