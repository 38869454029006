


























import { Component, Vue, Prop } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import ReminderTemplate from '@/modules/reminder-template/models/reminder-template.model'
import { GeneralRoutes } from '@/router/routes/general'
import DefaultTemplateService from '@/services/DefaultTemplateService';

@Component({
  components: { PageHeader, Form },
  methods: { __ }
})
export default class DefaultTemplatesEdit extends Vue {
  @Prop() meta!: any
  data: ReminderTemplate | any = {}
  error: any = {}
  tags: string[] = [
    '{client_company_name}',
    '{client_contact_full_name}',
    '{invoice_number}',
    '{invoice_total_with_vat}',
    '{invoice_date}',
    '{invoice_due_date}',
    '{invoice_left_pay}'
  ]

  async created(): Promise<void> {
    this.data = await DefaultTemplateService.get(this.$route.params.id)
  }

  async submitReminderTemplate(): Promise<void> {
    await DefaultTemplateService.edit(this.$route.params.id, this.data)
      .then(() => this.$router.push({ name: GeneralRoutes.defaultTemplatesIndex }))
      .catch((error: any) => {
        this.error = error
      })
  }
}
