

















import { Component, Vue, Prop } from 'vue-property-decorator'
import SelectField from '@/shared/classes/components/form/fields/select-field';
import SelectOption from '@/shared/classes/components/form/select-option'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import Offer from '@/modules/offer/models/offer.model'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import ArrayField from '@/shared/classes/components/form/fields/array-field'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { FieldTypes } from '@/shared/components/form/field-types'
import { heading, vatlessService, changeData } from '../../shared/configs/information-types.config'
import _ from 'lodash'
import { GeneralRoutes } from '@/router/routes/general'
import moment from 'moment'
import User from '../user/models/user.model'
import { mapGetters } from 'vuex'
import getAll from '@/shared/configs/vuex/get-all.config'
import { UserGetters } from '@/store/modules/user/user.getters'
import ClientNotFound from '@/shared/components/ClientNotFound.vue';
import { AxiosResponse } from 'axios';
import OffersSaveAsTemplate from '@/modules/offer/OffersSaveAsTemplate.vue';
import { InvoiceStatusKeysData, OfferStatusKeys } from '@/shared/configs/offer.config';

@Component({
  components: { PageHeader, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class OffersCreate extends Vue {
  @Prop() meta!: any
  user!: User
  loadingNumber: boolean = false
  validNumber: boolean = false
  formFields = [
    new SearchableField()
      .setVisibleIf(() => this.cloneUuid === null)
      .setOnChange(this.changeTemplate)
      .setKey('template_id')
      .setDisplayKey('title')
      .setTitle(__('views.offers.form.template'))
      .setSize(FieldSizes.half)
      .loadItems({
        endpoint: '/offer-templates',
        value: 'id',
        title: 'title'
      }),
    new SelectField()
      .setKey('status')
      .setRequiredDecoration(true)
      .setMultiple(false)
      .setTitle('Statusas')
      .setType(FieldTypes.select)
      .setSelectOptions([
        new SelectOption()
          .setKey(OfferStatusKeys.active)
          .setTitle(InvoiceStatusKeysData[OfferStatusKeys.active]),
        new SelectOption()
          .setKey(OfferStatusKeys.approved)
          .setTitle(InvoiceStatusKeysData[OfferStatusKeys.approved]),
        new SelectOption()
          .setKey(OfferStatusKeys.refused)
          .setTitle(InvoiceStatusKeysData[OfferStatusKeys.refused]),
        new SelectOption()
          .setKey(OfferStatusKeys.suspended)
          .setTitle(InvoiceStatusKeysData[OfferStatusKeys.suspended])
      ])
      .setSize(FieldSizes.half),
    new SearchableField()
      .setKey('client_id')
      .setLoadItemSort('-created_at')
      .setRequiredDecoration(true)
      .setDisplayKey('company_name')
      .setNotFoundComponent(ClientNotFound)
      .setTitle(__('views.offers.form.client'))
      .setSize(FieldSizes.full)
      .loadItems({
        endpoint: '/clients',
        value: 'id',
        title: 'company_name'
      }),
    new Field()
      .setRequiredDecoration(true)
      .setKey('date')
      .setTitle(__('views.offers.form.date'))
      .setSize(FieldSizes.half)
      .setType(FieldTypes.date),
    new Field()
      .setKey('due_date')
      .setRequiredDecoration(true)
      .setTitle(__('views.offers.form.due-date'))
      .setSize(FieldSizes.half)
      .setType(FieldTypes.date),
    new Field()
      .setKey('number')
      .setOnChange(_.debounce(this.checkIfValid, 400))
      .setTitle(__('views.offers.form.number'))
      .setSize(FieldSizes.half),
    new Field()
      .setKey('title')
      .setRequiredDecoration(true)
      .setTitle(__('views.offers.form.title'))
      .setSize(FieldSizes.half),
    new ArrayField()
      .isDraggable()
      .setKey('information')
      .setShowTotals(true)
      .setAddKeys(['heading', 'service'])
      .setFieldsTemplates({
        heading,
        service: vatlessService
      }),
    new SearchableField()
      .setKey('manager_id')
      .setRequiredDecoration(true)
      .setDisplayKey('full_name')
      .setTitle(__('views.offers.form.manager'))
      .setSize(FieldSizes.full)
      .loadItems({
        endpoint: '/users',
        value: 'id',
        title: 'full_name'
      }),
    new Field()
      .setKey('locale')
      .setTitle(__('views.offers.form.language'))
      .setType(FieldTypes.checkbox)
      .setCheckedValue('en')
      .setUncheckedValue('lt'),
    new Field()
      .setKey('notes')
      .setTitle('Pastabos')
      .setType(FieldTypes.richEditor)
  ]

  initialFormValues: any = {
    date: moment().format('YYYY-MM-DD'),
    due_date: moment().add(30, 'days').format('YYYY-MM-DD'),
    locale: 'lt',
    client_id: this.$route.params.clientId || null,
    status: OfferStatusKeys.active
  }

  form: FormBase = new FormBase()
    .setEndpoint('/offers')
    .setUuid(this.cloneUuid)
    .setPreventUuidSubmit(true)
    .setModel(Offer)
    .setOnSuccess((response: AxiosResponse) => {
      this.$router.push({ name: GeneralRoutes.offer, params: { id: response.data.id } })
    })
    .setCancel(() => this.$router.push({ name: GeneralRoutes.offersIndex }))
    .setFields(this.formFields)
    .setInitialValues({
      date: moment().format('YYYY-MM-DD'),
      due_date: moment().add(30, 'days').format('YYYY-MM-DD'),
      locale: 'lt',
      client_id: this.$route.params.clientId || null,
      status: OfferStatusKeys.active
    })
    .setChangeDataBeforeSubmit(changeData)

  async created(): Promise<void> {
    if (this.$route.query.request_id) {
      this.formFields.push(new Field()
        .setKey('request_id')
        .setRequiredDecoration(true)
        .setHidden(true))

      this.initialFormValues.request_id = String(this.$route.query.request_id)
    }
    this.form.setFields(this.formFields).setInitialValues(this.initialFormValues)

    await getAll(this.$store)

    http
      .get('offers/get-next-number')
      .then((response: IResponse<IModelResponse>) => {
        this.loadingNumber = true
        this.form.setData({
          ...this.form.data,
          number: response.data,
          manager_id: this.user.id,
          date: moment().format('YYYY-MM-DD')
        })
        this.validNumber = true
      })
      .finally(() => {
        this.loadingNumber = false
      })
  }

  checkIfValid(form: FormBase): any {
    if (!this.loadingNumber && form.data.number) {
      this.loadingNumber = true
      http
        .get(`offers/check-is-available/${form.data.number}`)
        .then((response: IResponse<IModelResponse>) => {
          if (!response.data) this.validNumber = false
          else this.validNumber = true
        })
        .finally(() => {
          this.loadingNumber = false
        })
    }
  }

  async changeTemplate() {
    await http
      .get(`offer-templates/${this.form.data.template_id}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        const group: any = _.find(this.form.fields, (field: any) => field.key === 'information')
        group.children = []
        item.data.information.forEach((row: any) => {
          group.addChildren(group.fieldTemplates[row.type], row.type, this.form)
        })
        this.form.setData({
          ...this.form.data,
          information: item.data.information,
          notes: item.data.notes || ''
        })
      })
  }

  createInvoice() {
    const { date, information, notes } = this.form.data
    const items = Object.keys(information)
      .filter((key: string) => information[key].type === 'service')
      .reduce((object: any, key: string) => {
        object[key] = information[key]
        return object
      }, {})

    const data: any = {
      client_id: this.form.data.client_id,
      date,
      due_date: this.form.data.due_date,
      items,
      manager_id: this.form.data.manager_id,
      notes
    }

    this.$router.push({
      name: GeneralRoutes.invoicesCreate,
      params: { data }
    })
  }

  get cloneUuid(): string | null {
    if (typeof this.$route.params.id !== 'undefined') {
      return String(this.$route.params.id)
    }
    return null
  }

  openSaveTemplateModal(): void {
    this.$store.commit('setModalDialog', {
      component: OffersSaveAsTemplate,
      id: 'offerCreate',
      props: {
        title: __('views.offers.save-template.title'),
        size: 'md',
        submitLabel: __('form.save')
      },
      details: {
        data: {
          information: this.processInformation(this.form.data.information),
          notes: this.form.data.notes
        }
      }
    })
  }

  processInformation(information: any) {
    if (typeof information !== 'object') {
      return information
    }
    const output: {[k: string]: any} = {}
    Object.keys(information).forEach((key) => {
      output[key] = {
        ...information[key],
        position: key
      }
    })
    return output
  }
}
