

































import { Component, Vue, Prop } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import { FieldSizes } from '@/shared/classes/components/form/field'
import SelectOption from '@/shared/classes/components/form/select-option'
import http from '@/shared/helpers/http'

@Component({
  components: { PageHeader },
  methods: { __ }
})
export default class ReminderTemplatesCreate extends Vue {
  @Prop() meta!: any
  error: any = null

  FieldSizes = FieldSizes
  tags: string[] = [
    '{client_company_name}',
    '{client_contact_full_name}',
    '{invoice_number}',
    '{invoice_total_with_vat}',
    '{invoice_date}',
    '{invoice_due_date}',
    '{invoice_left_pay}'
  ]

  options: SelectOption[] = [
    new SelectOption().setKey('every_day').setTitle('Kas diena'),
    new SelectOption().setKey('every_week').setTitle('Kas savaitę'),
    new SelectOption().setKey('every_month').setTitle('Kas mėnesį'),
    new SelectOption().setKey('every_year').setTitle('Kas metus')
  ]

  data: any = {
    title: '',
    time: '',
    body: '',
    reminderEnabled: false,
    interval: 'every_day'
  }

  submit(): void {
    http.post('reminder-templates', this.data).catch((error: any) => (this.error = error))
  }
}
