





import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { GeneralRoutes } from '@/router/routes/general'
import Offer from './models/offer.model'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class OffersDelete extends Vue implements Submittable {
  @Prop() itemId!: number
  offer: Offer | any = null

  async created(): Promise<void> {
    await http
      .get(`offers/${this.itemId}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.offer = item.data
      })
  }

  get title(): string {
    return this.offer ? `${this.offer.title} (${this.offer.number})` : ''
  }

  submitDialog(): void {
    http
      .delete(`offers/${this.offer.id}`)
      .then(() => {
        this.$store.commit('setModalDialog', null)
      })
      .then(() => {
        this.$router.push({ name: GeneralRoutes.offersIndex })
      })
  }
}
