


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Table from '@/shared/components/data-table/Table.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import Filters from '@/shared/components/data-table/Filters.vue'

@Component({
  components: { Table, Filters }
})
export default class DataTable extends Vue {
  @Prop() table!: DataTableBase
  @Prop() noRouterPagination!: boolean

  @Watch('table.activeTab') private handleActiveTabChanges(): void {
    this.refresh()
  }

  created(): void {
    this.table.setHeaders(this.table.headers.filter((header: DataTableHeader) => header.showCondition))
  }

  async refresh(): Promise<void> {
    if (this.table) return refreshTable(this.$refs.table)
  }

  get filtersCombined(): string {
    const ref: any = this.$refs.table
    return ref.filtersCombined
  }
}
